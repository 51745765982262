@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;1,400;1,500;1,600;1,900');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,400;1,500;1,600;1,900');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&display=swap');

//remove defualt heading
h1, h2, h3, h4, h5, h6{
  margin: 0;
  font-weight: 300;
}
.font-parent{
  font: inherit;
}
.h1 {
  font-size: 4.2rem !important;
}
.h2 {
  font-size: 3.2rem !important;
}
.h3 {
  font-size: 3rem !important;
}
.h4 {
  font-size: 2.8rem !important;
  line-height: 3.7rem;
}
.h5 {
  font-size: 2.6rem !important;
}

.h6 {
  font-size: 2.4rem !important;
}

.h7 {
  font-size: 2.2rem !important;
}

.h8 {
  font-size: 2.1rem !important;
}

.h9 {
  font-size: 2rem !important;
}

.h10 {
  font-size: 1.8rem !important;
}

.h11 {
  font-size: 1.6rem !important;
}

.h12 {
  font-size: 1.4rem !important;
}

.h13 {
  font-size: 1.2rem !important;
}

.h14 {
  font-size: 1rem !important;
}

.h15 {
  font-size: 0.8rem !important;
}
.f30 {
  font-size: 3rem;
}
.f28 {
  font-size: 2.8rem;
}
.f24 {
  font-size: 2.4rem !important;
}
.f22 {
  font-size: 2.2rem !important;
}
.f21 {
  font-size: 2.1rem;
}
.f20 {
  font-size: 2rem !important;
}
.f18 {
  font-size: 1.8rem !important;
}
.f16 {
  font-size: 1.6rem !important;
}
.f14 {
  font-size: 1.4rem !important;
}
.f12 {
  font-size: 1.2rem;
}
.f10 {
  font-size: 1rem;
}
.f9 {
  font-size: 0.9rem;
}
.f8 {
  font-size: 0.8rem;
}
.exo2 {
  font-family: 'Exo 2', sans-serif !important;
}
.garamond{
  font-family: 'EB Garamond', serif !important;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.med{
  font-weight: 500 !important;
}
.reg{
  font-weight: 400 !important;
}
@media only screen and (max-width: 599px){
  html{
    font-size: 6px;
  }
  .org-col-wrap{
    white-space: nowrap;
  }
}
/* sm: Small to medium tablet */
@media only screen and (min-width: 600px) and (max-width: 959px){
  html{
    font-size: 7px;
  }
  .org-col-wrap{
    white-space: nowrap;
  }
}
/* md: Large tablet to laptop */
@media only screen and (min-width: 960px) and (max-width: 1263px){
  html{
    font-size: 8px;
  }
}
/* lg: Desktop */
@media only screen and (min-width: 1264px) and (max-width: 1903px){
  html{
    font-size: 10px;
  }
}
/* xl: 4k and ultra-wide */
@media only screen and (min-width: 1903px){
  html{
    font-size: 10px;
  }
}