.input-group {
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  .error-text {
    margin-top: 8px;
  }
  label {
    color: #254343;
    margin: 0 4px 4px 0;
    position: relative;
    &.req::after {
      content: "*";
      color: rgb(244, 67, 54);
      position: absolute;
      font: inherit;
      top: 0;
      margin-left: 2px;
    }
  }

  .input-container {
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #254343;
    background-color: transparent;
    resize: none;
    min-height: 42px;
    max-height: 74px;
    font: inherit;
    &.disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  .dropdown-input {
    flex: 1;
  }
  .c254343 {
    color: #254343;
  }

  textarea,
  input {
    resize: none;
    background-color: transparent !important;
    border: none;
    outline: none;
    font: inherit !important;
    width: 100%;
    color: #254343;
    height: 100%;
  }

  /* do not group these rules */
  *::-webkit-input-placeholder {
    color: #868686;
    font-size: 2.2rem;
  }

  *:-moz-placeholder {
    /* FF 4-18 */
    color: #868686;
    font-size: 2.2rem;
  }

  *::-moz-placeholder {
    /* FF 19+ */
    color: #868686;
    font-size: 2.2rem;
  }

  *:-ms-input-placeholder {
    /* IE 10+ */
    color: #868686;
    font-size: 2.2rem;
  }

  *::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #868686;
    font-size: 2.2rem;
  }

  *::placeholder {
    /* modern browser */
    color: #868686;
    font-size: 2.2rem;
  }

  .max-len {
    width: 100%;
    text-align: right;
    margin: 1px 4px 0 0;
  }

  .MuiInputAdornment-root,
  .caret {
    // position: absolute;
    // right: 16px;
    // top: 50%;
    font-size: 8px;
    color: #1177cc;
  }

  .MuiInputAdornment-root {
    bottom: 16px;
  }

  .hint {
    font-size: 1.6rem;
    color: #254343;
    margin: 2px 0;
  }

  .hint-optinal {
    color: #868686;
  }

  .date-btn {
    position: relative;
    min-width: 130px;
    background-color: transparent;
    color: #1177cc;
    font: inherit;

    .MuiInputAdornment-root {
      right: 0;
      margin-left: 16px;
      bottom: 50%;

      svg {
        fill: #1177cc;
      }

      .MuiSvgIcon-root {
        font-size: 2.2rem !important;
      }
    }

    span {
      font: inherit;
    }
  }

  &.date {
    .MuiSvgIcon-root {
      font-size: 2.2rem !important;
    }
  }

  .two-level-btn-action {
    background-color: transparent;
    flex: 1;
    text-align: left;
    color: #254343;
    opacity: 0.7;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .error {
    box-shadow: 0px 0px 4px rgb(244, 67, 54);
    // border-color: rgb(244, 67, 54);
    border: none;
  }
}

.textarea-container {
  .input-container {
    max-height: 96px;
  }
}

.file-input-group {
  .input-container {
    justify-content: space-between;

    .file-label {
      color: #868686;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .preview {
    height: 40px;
    width: 50px;
    object-fit: contain;
    margin-right: 12px;
  }
}

.input-group.checkbox {
  .input-container {
    border-color: transparent;
    padding-left: 0;
  }
  &.input-noedit {
    .input-container {
      padding-left: 15px;
    }
  }
}

.input-noedit {
  .input-container {
    border-color: transparent;
    background-color: transparent;
  }
  &.select {
    .icon-chavron-down {
      display: none;
    }
  }
}
.placeholder-size-16::placeholder {
  font-size: 16px !important;
}
