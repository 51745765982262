@import "./font.scss";
* {
  // color: #4f4f4f;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box !important;
}
.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.v-ctr{
  align-items: center;
}
.h-ctr{
  justify-content: center;
}
.v-start {
  align-items: flex-start;
}
.v-end {
  align-items: flex-end;
}
.h-btn {
  justify-content: space-between;
}
.h-arnd {
  justify-content: space-around;
}
.h-end {
  justify-content: flex-end;
}
.flex-full,
.f-rest{
  flex: 1;
}
.o-hide{
  overflow: hidden;
}
.oy-auto{
  overflow: hidden auto;
}
body{
  width: 100vw;
  height: 100vh;
  margin: 0;
}
// html{
//   font-size: 10px;
// }
.overlay-load{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.h-50{
  height: 50%;
}
.h-30{
  height: 30%;
}
.p{
  margin: 0;
}
a{
  color: #1177CC;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
.bg-primary{
  background-color: #238787;
}
.c-primary{
  color: #238787;
}
input{
  appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.main {
  overflow: hidden;
}
.screen{
  background-color: rgba(35, 135, 135, 0.05);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hsc::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hsc {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.home{
  margin: auto;
}

.tog-tbl{
  .collapse{
    max-height: 0;
    transition: max-height 0.75s ease-in-out;
    overflow: hidden;
  }
  .collapse.show{
    max-height: 520px;
  }
  .rt{
    transform:rotate(180deg);
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
    &.show{
      transform:rotate(0deg);
    }
  }
  .tbl-org{
    padding-bottom: 32px;
  }
  .btn-map{
    width: 245px;
    padding-left: 0;
    .arrow,
    .btn-text{
      color: #000000;
    }
    .btn-text{
      text-transform: none;
    }
  }
}
.search-input{
  max-width: 500px;
  width: 23%;
  .search-icon{
    color: #707070;
  }
}

.no-scroll-bar-g{
  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #254343;
    border-radius: 9px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #254343;
  }
}
.page-head{
  color: #073C3C;
  margin-bottom: 12px;
}
.hide{
  display: none;
}
.soft-hide{
  visibility: hidden;
}
.dialog {
  .dialog-paper-root {
    max-width: 95%;
  }
}
.cFF3333{
  color: #FF3333 !important;
}
.c00CC55{
  color: #00CC55 !important;
}
.c00085{
  color: rgba(0, 0, 0, 0.85);
}
.cC00{
  color: #C00000;
}
.c777{
  color: #777;
}
.c1155CC{
  color: #1155CC;
}
.cBB2222{
  color: #BB2222;
}
.c0133CC{
  color: #0133CC;
}
.c00{
  color:#000000;
}
.blink_me {
  animation: blinker 2s linear 2;
}
.proper-case{
  text-transform: capitalize;
}
.actions .activate, .actions .accept{
  color: #00CC55;
  text-transform: capitalize;
}
.actions .deactivate, .actions .reject{
  color: #FF3333;
  text-transform: capitalize;
}
.loading-indicator .loading {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}
.loading-indicator.small .loading {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.loading-indicator .loading-oval {
  width: 240px;
  height: 240px;
}
.loading-indicator.small .loading-oval {
  width: 64px;
  height: 64px;
}
.capital{
  text-transform: capitalize;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.info-dialog{
  max-width: 50vw !important;
  max-height: 90vh !important;
  .title{
    border-bottom: 1px solid #e9ecef;
  }
  .actions{
    border-top: 1px solid #e9ecef;
  }
}
.no-wrap{
  white-space: nowrap;
}
.mt-1{
  margin-top: 1rem;
}
.mt-3{
  margin-top: 3rem;
}
.mb-3{
  margin-bottom: 3rem;
}

.mb-5{
  margin-bottom: 5rem;
}
.mb-1{
  margin-bottom: 1.5rem;
}
.mr-1{
  margin-right: 1rem;
}
.ml-1{
  margin-left: 1rem !important;
}
.logo-link{
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
}
.box-content-center{
  justify-content: center;
  align-items: center;
  display: flex;
}

.d-flex{
  display: flex;
}

.flex-grow-1{
  flex-grow: 1;
}
.org-col-wrap{
  white-space: nowrap;
}
.organiser-tbl{
  .link-btn{
    width: 100%;
  }
}
.justified-text {
  text-align: justify;
  text-justify: inter-word;
  hyphens: auto; /* Enable automatic hyphenation */
}


/*
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    html{
      font-size: 7px;
    }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
  html{
    font-size: 8px;
  }
}
@media screen 
  and (min-device-width: 1601px) {
  html{
    font-size: 10px;
  }
}*/
/* xs: Small to large phone */

