.org-add {
  padding: 4.1em 10.4em;

  .form {
    padding-top: 24px;
    padding-right: 3rem;
    padding-left: 4px;

    .label {
      color: #254343;
    }

    .caret {
      color: #254343;
    }
  }
  .tab-container {
    padding: 16px 0 0 8px;
  }
}
.tab-ceo {
  .form-actions {
    margin-top: 24px;
    .app-btn {
      margin-right: 16px;
    }
  }
}
#org-form {
  .reset-btn {
    margin-left: 24px;
  }
  .btn-deact {
    align-self: flex-end;
  }
}
.org-det {
  padding: 1rem 4.1rem 4.1rem;
}

.orgd {
  padding-right: 6rem;
  padding-top: 3rem;

  .title {
    color: #073c3c;
  }

  .user-sec {
    margin-top: 36px;
    min-height: 52px;
  }
  .sec {
    padding: 3rem 0 0 3.5rem;

    .label {
      color: #254343;
    }

    .value {
      color: rgba(134, 134, 134, 0.8);
      margin: 1.25rem 0 0 1.5rem;
    }

    .item {
      padding-bottom: 3rem;
    }
  }

  .btn-change-org {
    padding: 0;
    margin: 8px 24px 0;
  }
  .org-user-form {
    .org-user-input {
      margin: 0 0 0 8px;
    }
    .btn {
      margin-left: 24px;
      margin-right: 8px;
    }
  }
  .update-cell {
    padding: 4px 0;
    .app-btn {
      margin-left: 8px;
    }
    .input-group {
      margin-bottom: 0;
    }
  }
  .update-btn {
    width: 150px;
  }
  .action-head {
    padding-left: 44px;
    text-align: left;
  }
}

.org-invite {
  padding: 0 4rem 3rem 3rem;

  .invite-container {
    padding-top: 3rem;
  }

  .invite-card {
    padding-left: 4rem;
    padding-top: 3rem;
  }

  .tab-btn {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #fff;
    color: #262626;
    margin: 0 8px;
  }

  .tab-btn.active {
    background-color: #238787;
    color: #fff;
  }

  .tab-divider {
    height: 1px;
    border-bottom: 1px dashed #707070;
  }

  .btn-invite {
    width: 160px;
    margin-top: 24px;
  }
  .sum-title {
    color: #073c3c;
    margin: 0 0 2rem -5rem;
  }
  .btn-invite.req {
    width: 232px;
  }
  .check-msg {
    color: #073c3c;
    margin: 8px 0;
  }
  .tab-container {
    padding: 3rem 16px 0 1rem;
  }
  .success {
    margin-top: 8px;
    color: #00cc55;
  }
  .success-note {
    color: #254343;
  }
  .btn-ok {
    width: 84px;
    margin-top: 32px;
  }

  .mod-list-cont {
    padding-top: 24px;

    .title {
      color: #707070;
    }

    .mod-list {
      margin-top: 24px;
      height: 234px;
      overflow: hidden auto;
      border: 1px solid #707070;
      padding: 18px 32px;
    }

    .def-mod {
      .checkbox.checked {
        background-color: #707070;
        border-color: #707070;
      }
    }
  }
}

.invite-confirm {
  padding: 0;
  max-width: 420px !important;
  border-radius: 16px !important;
  border: 1px solid #333;
  .content {
    padding: 24px 48px 16px 16px;
  }
  .btn-ok {
    background-color: #ff3333 !important;
  }
  .action-row {
    width: 100%;
    margin-top: 0;
    justify-content: space-between;
    padding: 0 28px 16px 28px;
  }
  .heading {
    color: #ff3333;
  }
  .icon-checkmark {
    color: #fff;
  }
}

.org-main {
  padding: 2rem 5rem;

  .back-btn {
    background-color: transparent !important;
    color: #073c3c;
    .btn-text {
      text-transform: none;
    }
    .back-icon {
      margin-right: 24px;
    }
  }

  .btn-edit {
    margin-top: 24px;
  }

  .navbar {
    padding: 12px 0;
    margin-left: 65px;
    width: 50%;

    .items {
      padding: 0;
    }
  }

  .org-int-ro {
    padding-left: 65px;
    position: relative;
  }

  .btn-enable {
    margin-top: 24px;
    color: #254343;
    margin-left: 16px;
  }
  .user-add {
    margin-top: 24px;
  }
}

.org-int-det {
  position: relative;
  .orgd {
    padding: 0;
    position: relative;
    .sec {
      padding-left: 2.5rem;
    }
  }
  .btn-sec {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
.tbl-asmt {
  .input-group {
    margin-bottom: 0;
    .input-container {
      .caret {
        color: #707070;
      }
    }
  }
}
.org-int-user {
  .btn-invite {
    position: absolute;
    top: 0;
    right: 12rem;
    width: 213px;
  }
  .search {
    width: 36%;
    max-width: 500px;
    margin-top: 16px;
  }
  .inactive-head {
    color: #ff3333;
    margin: 8px 0;
  }
}
.org-add-success {
  .heading {
    color: #00cc55;
  }
}
.asmt-dialog {
  .key {
    min-width: 8vw;
    margin: 14px 14px 14px 0;
  }
  .load {
    height: 250px;
    width: 300px;
  }
}
.orginer-tbl {
  width: 75%;
  table {
    th {
      color: #254343;
      text-align: left;
      padding: 4px;
    }
    td {
      padding: 4px;
      color: rgba(134, 134, 134, 0.8);
    }
  }
}
.org-int-mod {
  margin-top: 16px;
}
.senior-dialog {
  width: 60vw;
  max-width: 80vw !important;
  .form-actions {
    justify-content: flex-end;
  }
}
.nominate-dialog {
  width: 520px;
  .action {
    padding: 16px 24px;
  }
  .warning-icon {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }
  .text {
    margin-bottom: 0;
    line-height: 1.5;
  }
  .user-sec {
    margin: 24px 0;
    .title {
      margin-bottom: 8px;
    }
  }
  .field {
    margin-bottom: 4px;
  }
  .label {
    width: 56px;
  }
  .label-pos {
    width: 130px;
  }
  .link {
    color: #1177cc;
  }
  .email-input {
    margin: 24px 0 0 0;
    .label {
      width: 100% !important;
      color: #777 !important;
    }
    .input-container {
      height: 44px;
    }
  }
}
